import React from 'react';
import { searchIconSrc } from '../../styles/icons/icons';

interface Props {
    inputName: string
    searchText: string;
    handleSearchMessages: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export default function MessagesSearchBar({
    inputName,
    searchText,
    handleSearchMessages
}: Props) {
    return (
        <div className="input-group mb-3">
            <input
                data-testid="messagesSearchBar"
                autoFocus
                type="search"
                name={`${inputName}`}
                value={searchText}
                onChange={e => handleSearchMessages(e)}
                className="form-control"
                placeholder=""
                aria-label="searchMessages"
                aria-describedby="button-addon2"
            />
            <button className="btn btn-primary" type="button" id="button-addon2">
                <img src={searchIconSrc} alt="search icon" className="filter-white"/>
            </button>
        </div>
    )
}