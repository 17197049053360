import { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Buffer } from 'buffer';
import API from '../../utils/API';
import { formatApplicantDataObject, formatVendorDataObject, formatMessageSubject, formatMessageBody, addDays, determineLaterDate } from '../../utils/helperFunctions';
import LoadingSpinnerText from '../LoadingSpinnerText/LoadingSpinnerText';
import MessageCard from '../MessageCard/MessageCard';
import Mustache from "mustache";
import { useParams } from 'react-router-dom';
import MessagePreviewSection from '../MessagePreviewSection/MessagePreviewSection';
import SelectedEntitySection from '../SelectedEntitySection/SelectedEntitySection';
import { EnvironmentSettings } from '../../App';
import FinalMessageScreen from '../FinalMessageScreen/FinalMessageScreen';
import ChoosePersonnel from '../ChoosePersonnel/ChoosePersonnel';
import MessagesSearchBar from '../MessagesSearchBar/MessagesSearchBar';
import NotesField from '../NotesField/NotesField';
import ReassignmentRequestConfirmedInputField from '../ReassignmentRequestConfirmedInputField/ReassignmentRequestConfirmedInputField';
import ButtonNavigation from '../ButtonNavigation/ButtonNavigation';
import { Message, MessageLog, ViewDataInterface, CaseExam, VerifyGuidReqBody, SenderDataInterface, MsgData } from '../../utils/interfaces';
import ChooseVendorEmailRecipient from '../ChooseVendorEmailRecipient/ChooseVendorEmailRecipient';
import dodmerbMessageChoices from '../../utils/dodmerbMessageChoices';




export const partials = {
    dodmerb_helpdesk_email: '<a href="mailto: usaf.dodmerb.helpdesk@mail.mil">usaf.dodmerb.helpdesk@mail.mil</a>',
    dodmerb_helpdesk_phone: '<a href="tel: 719-333-3562">719-333-3562</a>',
}



export enum EntityTypeEnum {
    APPLICANT = "applicant",
    VENDOR = "vendor",
    DODMERB = "dodmerb",
    AIR_FORCE = "air force"
}


interface Props {
    environmentSettings: EnvironmentSettings;
    logger: any;
}

export default function ErrorManagement({ environmentSettings, logger }: Props) {



    let { backendApiUrl, showCacheControls } = environmentSettings;


    const colors = "color: white; background: orange";
    const colors2 = "color: white; background: blue";

    // Params from the url
    type Param = {
        verificationCode: string;
        applicantId: string;
        caseExamId: string;
        vendorCode: string;
        userName: string;
    };

    // These params will always be strings, and never undefined. If this page loads, it means all params are defined in the url.
    let { verificationCode, applicantId, caseExamId, vendorCode, userName } = useParams<keyof Param>() as Param;

    // request body for verifying guid
    // const reqBody = {
    //     guid: verificationCode,
    //     idValue: caseExamId
    // }


    // Mustache view data (inputs for the templates. Some templates need these inputs)
    const [viewData, setViewData] = useState<ViewDataInterface>({
        zip_code: "",
        status: "",
        status_explanation: "",
        closest_provider_address: "",
        date_exams_sent: "",
        exam_type: "",
        provider_name: "",
        provider_phone: ""
    });

    const [originalProviderAddress, setOriginalProviderAddress] = useState("");

    const resetProviderAddressAndViewData = () => {
        setViewData({ ...viewData, closest_provider_address: originalProviderAddress });
    }

    const [confidentialityNotice, setConfidentialityNotice] = useState("");


    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");

    const [selectedEntity, setSelectedEntity] = useState("");
    const [applicantChosen, setApplicantChosen] = useState(false);
    const [vendorChosen, setVendorChosen] = useState(false);
    const [dodmerbPersonnelChosen, setDodmerbPersonnelChosen] = useState(false);
    const [selectedVendorRecipientEmail, setSelectedVendorRecipientEmail] = useState("");
    const [isVendorRecipientSelected, setIsVendorRecipientSelected] = useState(false);

    // Messages
    const [applicantMessageChoices, setApplicantMessageChoices] = useState<any[]>([]);
    const [vendorMessageChoices, setVendorMessageChoices] = useState<any[]>([]);


    const [senderName, setSenderName] = useState(""); // This is the name of the logged in user

    const [senderData, setSenderData] = useState<SenderDataInterface>({
        phone: "",
        fax: "",
        email: ""
    })

    const [showFinalApplicantSendMessageScreen, setShowFinalApplicantSendMessageScreen] = useState(false);
    const [showFinalVendorSendMessageScreen, setShowFinalVendorSendMessageScreen] = useState(false);
    const [showFinalDodmerbSendMessageScreen, setShowFinalDodmerbSendMessageScreen] = useState(false);
    const [sendingMessages, setSendingMessages] = useState(false);
    const [messagesSent, setMessagesSent] = useState(false);


    // notes
    const [notesField, setNotesField] = useState("");

    // Messages
    const [chosenApplicantMessageIds, setChosenApplicantMessageIds] = useState<string[]>([]);
    const [applicantMessagesToSend, setApplicantMessagesToSend] = useState<Message[]>([]);
    const [chosenVendorMessageIds, setChosenVendorMessageIds] = useState<string[]>([]);
    const [vendorMessagesToSend, setVendorMessagesToSend] = useState<Message[]>([]);
    const [chosenDodmerbMessageIds, setChosenDodmerbMessageIds] = useState<string[]>([]);
    const [dodmerbMessagesToSend, setDodmerbMessagesToSend] = useState<Message[]>([]);



    const selectEntity = (entity: EntityTypeEnum) => {
        setSelectedEntity(entity);

        if (entity === EntityTypeEnum.APPLICANT) {
            setApplicantChosen(true);
        } else if (entity === EntityTypeEnum.VENDOR) {
            setVendorChosen(true);
        }
    }

    const goToFinalApplicantScreen = () => setShowFinalApplicantSendMessageScreen(true);
    const goToFinalVendorScreen = () => setShowFinalVendorSendMessageScreen(true);
    const goToFinalDodmerbScreen = () => setShowFinalDodmerbSendMessageScreen(true);

    // ===============================================
    // Search results
    // ===============================================
    const [dodmerbResults, setDodmerbResults] = useState<Message[]>(dodmerbMessageChoices);
    const [dodmerbSearchText, setDodmerbSearchText] = useState("");
    const [vendorResults, setVendorResults] = useState<Message[]>(vendorMessageChoices);
    const [vendorSearchText, setVendorSearchText] = useState("");
    const [applicantResults, setApplicantResults] = useState<Message[]>(applicantMessageChoices);
    const [searchText, setSearchText] = useState("");

    const [confirmReassignmentChosen, setConfirmReassignmentChosen] = useState(false);
    const updateClosestProvider = (address: string) => {
        setViewData({ ...viewData, closest_provider_address: address })
    }

    // ===============================================
    // Choose message
    // ===============================================
    const chooseApplicantMessage = (chosenMessage: Message) => {
        // logger.info("chosenMessage: ", chosenMessage);


        let { id: chosenId } = chosenMessage;
        let messageIsAlreadyChosen = chosenApplicantMessageIds.includes(chosenId); // Check if the message is already chosen

        if (messageIsAlreadyChosen) {
            let newArr = chosenApplicantMessageIds.filter((id: string) => id !== chosenId);
            setChosenApplicantMessageIds(newArr);
            let msgs = applicantMessagesToSend.filter((msg: Message) => msg.text !== chosenMessage.text);
            setApplicantMessagesToSend(msgs)

            setConfirmReassignmentChosen(false);

        } else {
            let newArr = [...chosenApplicantMessageIds, chosenId];
            setChosenApplicantMessageIds(newArr);

            let msgs = [...applicantMessagesToSend, chosenMessage];
            setApplicantMessagesToSend(msgs);

            // if chosen message.subject is Confirm Reassignment && the message is not already chosen, setConfirmReassignmentChosen to true
            if (chosenMessage.subject === " Reassignment Request Confirmed") {
                setConfirmReassignmentChosen(true);
            }

        }
        setApplicantResults(applicantMessageChoices);
        setSearchText("");
    }

    const chooseVendorMessage = (chosenMessage: Message) => {

        let { id: chosenId } = chosenMessage;
        let idIsChosen = chosenVendorMessageIds.includes(chosenId);

        if (idIsChosen) {
            let newArr = chosenVendorMessageIds.filter((id: string) => id !== chosenId);
            setChosenVendorMessageIds(newArr);
            let msgs = vendorMessagesToSend.filter((msg: Message) => msg.text !== chosenMessage.text);
            setVendorMessagesToSend(msgs)
        } else {
            let newArr = [...chosenVendorMessageIds, chosenId];
            setChosenVendorMessageIds(newArr);

            let msgs = [...vendorMessagesToSend, chosenMessage];
            setVendorMessagesToSend(msgs);
        }
        setVendorResults(vendorMessageChoices);
        setVendorSearchText("");
    }

    const chooseDodmerbMessage = (chosenMessage: Message) => {

        let { id: chosenId } = chosenMessage;
        let idIsChosen = chosenDodmerbMessageIds.includes(chosenId);

        if (idIsChosen) {
            let newArr = chosenDodmerbMessageIds.filter((id: string) => id !== chosenId);
            setChosenDodmerbMessageIds(newArr);
            let msgs = dodmerbMessagesToSend.filter((msg: Message) => msg.text !== chosenMessage.text);
            setDodmerbMessagesToSend(msgs)
        } else {
            let newArr = [...chosenDodmerbMessageIds, chosenId];
            setChosenDodmerbMessageIds(newArr);

            let msgs = [...dodmerbMessagesToSend, chosenMessage];
            setDodmerbMessagesToSend(msgs);
        }
        setDodmerbResults(dodmerbMessageChoices);
        setDodmerbSearchText("");
    }

    const applicantGoBack = () => {
        setApplicantChosen(false);
        setSelectedEntity("");
        setSearchText("");
        setApplicantResults(applicantMessageChoices);
        setApplicantMessagesToSend([]);
        setChosenApplicantMessageIds([]);
        setConfirmReassignmentChosen(false);

        resetProviderAddressAndViewData();
    }

    const vendorGoBackFromMessageChoices = () => {
        // setVendorChosen(true); // should remain as true
        setSelectedEntity(EntityTypeEnum.VENDOR);
        setVendorSearchText("");
        setVendorResults(vendorMessageChoices);
        setVendorMessagesToSend([]);
        setChosenVendorMessageIds([]);

        setIsVendorRecipientSelected(false);
        setSelectedVendorRecipientEmail("");
    }

    const dodmerbGoBack = () => {
        setSelectedDodmerbPersonnelId("");
        setDodmerbPersonnelChosen(false);
        setDodmerbSearchText("");
        setDodmerbResults(dodmerbMessageChoices);
        setDodmerbMessagesToSend([]);
        setChosenDodmerbMessageIds([]);
    }

    const handleCancel = () => {
        setSelectedEntity("");
        setApplicantChosen(false);
        setVendorChosen(false);
        setDodmerbPersonnelChosen(false);

        setSelectedDodmerbPersonnelName("");
        setSelectedDodmerbPersonnelEmail("");
        setSelectedDodmerbPersonnelId("");

        setShowFinalApplicantSendMessageScreen(false);
        setShowFinalVendorSendMessageScreen(false);
        setShowFinalDodmerbSendMessageScreen(false);

        setChosenApplicantMessageIds([]);
        setApplicantMessagesToSend([]);
        setChosenVendorMessageIds([]);
        setVendorMessagesToSend([]);
        setChosenDodmerbMessageIds([]);
        setDodmerbMessagesToSend([]);

        setNotesField("");
        setMessagesSent(false);
        setConfirmReassignmentChosen(false);


        resetProviderAddressAndViewData();
        setSelectedVendorRecipientEmail("");
        setIsVendorRecipientSelected(false);
    }


    const chosenMessageStyle = "card mb-2 lightCard pointer p-2 shadow-sm border border-primary";
    const notChosenMessageStyle = "card mb-2 lightCard pointer p-2 shadow-sm";



    const [applicantData, setApplicantData] = useState<any>();
    const [vendorData, setVendorData] = useState<any>();
    const [clientData, setClientData] = useState<any>();



    const [selectedDodmerbPersonnelId, setSelectedDodmerbPersonnelId] = useState("");
    const [selectedDodmerbPersonnelName, setSelectedDodmerbPersonnelName] = useState("");
    const [selectedDodmerbPersonnelEmail, setSelectedDodmerbPersonnelEmail] = useState("");

    const selectPersonnel = (id: string) => {

        setSelectedDodmerbPersonnelId(id);

        let person = clientData.find((person: any) => person.id === (parseInt(id)))
        let { firstName, lastName, email } = person;

        setSelectedDodmerbPersonnelName(`${firstName} ${lastName}`);
        setSelectedDodmerbPersonnelEmail(email);
    };

    const cancelSelectPersonnel = () => {
        setDodmerbPersonnelChosen(false); setSelectedEntity(""); setSelectedDodmerbPersonnelEmail(""); setSelectedDodmerbPersonnelName(""); setSelectedDodmerbPersonnelId("");
    }

    const searchMessages = (searchText: string, searchType: EntityTypeEnum) => {

        if (searchType === EntityTypeEnum.APPLICANT) {
            let results = applicantMessageChoices.filter(msg => msg.text.toLowerCase().includes(searchText.toLowerCase()) || msg.subject.toLowerCase().includes(searchText.toLowerCase()));
            setApplicantResults(results);
        } else if (searchType === EntityTypeEnum.VENDOR) {
            let results = vendorMessageChoices.filter(msg => msg.text.toLowerCase().includes(searchText.toLowerCase()) || msg.subject.toLowerCase().includes(searchText.toLowerCase()));
            setVendorResults(results);
        } else if (searchType === EntityTypeEnum.DODMERB) {
            let results = dodmerbMessageChoices.filter(msg => msg.text.toLowerCase().includes(searchText.toLowerCase()) || msg.subject.toLowerCase().includes(searchText.toLowerCase()));
            setDodmerbResults(results);
        }
    }

    const renderMessageBody = (index: number, msg: Message) => {
        let view = {
            body: msg.text,
            ...viewData
        };
        let mustacheOutput = Mustache.render(msg.text, view, partials);
        return (`<div><strong>${index + 1}. ${msg.subject}</strong> <div>${mustacheOutput}</div></div>`)
    }

    const formatEmailData = () => {
        let recipientEmail: string = "";
        let subject: string = "";
        let messageBody: string = "";
        let recipientName: string = "";


        if (selectedEntity === EntityTypeEnum.APPLICANT) {
            recipientEmail = applicantData.email;
            recipientName = `${applicantData.firstName} ${applicantData.lastName}`
            let messages = applicantMessagesToSend.map((msg, index) => renderMessageBody(index, msg));
            messageBody = formatMessageBody(messages, notesField, recipientName);
            subject = formatMessageSubject(applicantMessagesToSend);
        } else if (selectedEntity === EntityTypeEnum.VENDOR) {
            recipientEmail = vendorData.email1;
            recipientName = `${vendorData.vendorName}`;
            let messages = vendorMessagesToSend.map((msg, index) => renderMessageBody(index, msg));
            messageBody = formatMessageBody(messages, notesField, recipientName);
            subject = formatMessageSubject(vendorMessagesToSend);
        } else if (selectedEntity === EntityTypeEnum.DODMERB) {
            recipientEmail = selectedDodmerbPersonnelEmail;
            recipientName = selectedDodmerbPersonnelName;
            let messages = dodmerbMessagesToSend.map((msg, index) => renderMessageBody(index, msg));
            messageBody = formatMessageBody(messages, notesField, recipientName);
            subject = formatMessageSubject(dodmerbMessagesToSend);
        }

        let formattedEmail = {
            recipientEmail,
            subject,
            messageBody
        }

        return formattedEmail
    }

    // Working ✔
    const sendResponseMsgToDodmetsAPI = (msgData: MsgData, isSent: boolean) => {

        // get date 7 days in future
        let sevenDaysInFuture = addDays(7).toISOString();
        
        const reqBody = {
            guid: verificationCode,
            idValue: caseExamId
        }

        let msgLog: MessageLog = {
            ...reqBody,
            "entityId": 0, // NOTE: use the applicant id 
            "isSent": isSent,
            "isValidRecipient": true,
            "processId": "string", // NOTE:  CIVMETS-QUICK-MESSAGEs i.e. the application name (send the url, just the deployment url not params no https no protocol)
            "entityType": "string", // NOTE: messageTopic 
            "description": `sent message from ${msgData.senderName} to ${msgData.recipientEmail} subject: ${msgData.subject}`,
            "stamp": new Date().toISOString(),
            "stampUtc": new Date().toISOString(),
            "daysRelevancyExpiresAfter": 7, // NOTE: make 3.. if urgent make 1 day? 
            "dateRelevancyExpiresOn": sevenDaysInFuture // Automatically calculated

            // to be included: sender (as userName / email) and recipient (string). These will be objects
        }

        API.createMessageLog(backendApiUrl, msgLog)
            .then(res => {
                logger.info("send response message to api res: ", res)
            })
            .catch(err => logger.info("send response message to api error: ", err));

    }

    // Working ✔
    const sendMessages = () => {

        if (selectedEntity) {
            setSendingMessages(true);

            let formattedEmail = formatEmailData();

            const msgData: MsgData = {
                guid: verificationCode,
                idValue: caseExamId,
                recipientEmail: formattedEmail.recipientEmail,
                messageBody: formattedEmail.messageBody,
                subject: formattedEmail.subject,
                senderName: senderName,
                confidentialityNotice: confidentialityNotice,
                senderData: senderData
            }
            logger.info("msg Data: ", msgData);

            API.sendCannedMessage(msgData, backendApiUrl)
                .then(res => {
                    logger.info("canned msg res: ", res);
                    sendResponseMsgToDodmetsAPI(msgData, true);
                    setSendingMessages(false);
                    setMessagesSent(true);
                    setConfirmReassignmentChosen(false);
                })
                .catch(err => {
                    logger.error(err);
                    sendResponseMsgToDodmetsAPI(msgData, false);
                    setConfirmReassignmentChosen(false);
                })
        }
    }


    const [cacheKeys, setCacheKeys] = useState<string[]>([]);
    const [newCacheKey, setNewCacheKey] = useState("");

    const removeCacheKey = (key: string) => {
        let filtered = cacheKeys.filter(item => item !== key);
        setCacheKeys(filtered);
    }

    const [isClearingCache, setIsClearingCache] = useState(false);
    const [cacheCleared, setCacheCleared] = useState(false);
    const [cacheClearError, setCacheClearError] = useState(false);

    const clearCache = () => {

        setIsClearingCache(true);
        API.clearCache(backendApiUrl, cacheKeys)
            .then(res => {
                logger.info("clear cache res: ", res.data);
                setIsClearingCache(false);
                setCacheCleared(true);
                setCacheClearError(false);
                setCacheKeys([]);
            })
            .catch(err => {
                logger.error("clear cache err: ", err);
                setIsClearingCache(false);
                setCacheCleared(false);
                setCacheClearError(true);
            })
    }

    const addCacheKey = () => {
        setCacheKeys([...cacheKeys, newCacheKey]);
        setNewCacheKey("");
    }



    // Called upon page load to get: applicant data & vendor data, client data, and confidentiality notice
    useEffect(() => {


        // ✔ Working
        const getClientData = async () => {

            const reqBody = {
                guid: verificationCode,
                idValue: caseExamId
            }

            API.getClientData(backendApiUrl, reqBody)
                .then(res => {
                    logger.info("%cclient data: ", colors, res);
                    setClientData(res.data);
                })
                .catch(err => {
                    logger.error(err);
                    setError(true);
                    setErrorMsg('Error retrieving client data');
                    setLoading(false);
                    setLoaded(true);
                })
        }
        // Working ✔
        const getConfidentialityNotice = () => {

            API.getConfidentialityNotice(backendApiUrl)
                .then(res => {
                    setConfidentialityNotice(res.data);
                }).catch(err => {
                    logger.error(err);
                    setErrorMsg('Error retrieving confidentiality notice');
                })
        }
        // Working ✔
        const getLoginToken = async () => {
            try {
                let res = await API.loginToBackend(backendApiUrl);
                logger.info("login res ==> : ", res);
                let result = res.data;
                return result;
            } catch (err) {
                logger.error(err);
                setError(true);
                setErrorMsg('Error retrieving login token');
            }
        }

        // Working ✔
        const getMessageChoicesByTemplateGroup = (templateGroup: string) => {
            API.getMessageChoicesByTemplateGroup(templateGroup, backendApiUrl)
                .then(res => {
                    logger.info("messages by template group: ", res);
                    interface Msg {
                        fileName: string;
                        subject: string;
                        template: string;
                    }

                    let messages = res.data.files.map((msg: Msg, index: number) => {
                        return ({
                            id: index, subject: msg.subject, text: msg.template
                        })

                    });

                    if (templateGroup === "applicants") {
                        setApplicantMessageChoices(messages);
                        setApplicantResults(messages);
                    } else if (templateGroup === "vendors") {
                        setVendorMessageChoices(messages);
                        setVendorResults(messages);
                    } else if (templateGroup === "candidates") {

                    }
                    // logger.info("messages: ", messages);
                })
                .catch(err => {
                    setError(true);
                    setErrorMsg('Error retrieving message choices');
                    logger.error(err)
                })
        }

        const getApplicantAndVendorData = async () => {
            if (applicantId !== undefined) {
                let ApplicantId = parseInt(applicantIdDecoded);

                logger.info(applicantId, ApplicantId);

                let reqBody = {
                    ApplicantId,
                    guid: verificationCode,
                    idValue: caseExamId
                }

                logger.info(reqBody);

                API.getApplicantData(reqBody, backendApiUrl)
                    .then(res => {
                        // ===========================
                        // Applicant Data
                        // ===========================
                        logger.info("res: ", res);
                        let data = res.data;
                        logger.info("%c applicant data: ", colors, data);
                        let { applicant } = data;

                        let applicantData = formatApplicantDataObject(applicant);
                        setApplicantData(applicantData);


                        const areBothCasesClosed = data.caseExams.every((item: CaseExam) => item.status === "Closed");
                        logger.info("areBothCasesClosed?: ", areBothCasesClosed);

                        let dateExamsSent = "";

                        if (areBothCasesClosed) {

                            let dates = data.caseExams.map((exam: any) => new Date(exam.dateStatus));
                            let closeDate = determineLaterDate(dates);

                            logger.debug("closeDate is: ", closeDate);

                            let dddmmyyyy = `${closeDate.getMonth() + 1}/${closeDate.getDate()}/${closeDate.getFullYear()}`;
                            logger.debug("dddmmyyyy is: ", dddmmyyyy);
                            dateExamsSent = dddmmyyyy;
                        }


                        // check the postal (zip) code
                        let preferredPostalCode = "";

                        if (applicant.examCenterMatchBy === "detachment") {
                            if (data.unit) { // not all applicants have a unit (4 yrs do not)
                                if (data.unit.zipCode) {
                                    preferredPostalCode = data.unit.zipCode;
                                }
                            } else { // if no unit exists, set preferred postal code to applicant's zip
                                preferredPostalCode = applicant.zip;
                            }

                        } else if (applicant.examCenterMatchBy === "applicant") {
                            preferredPostalCode = applicant.zip;
                        } else if (applicant.examCenterMatchBy === "alternate") {
                            preferredPostalCode = applicant.examCenterMatchAlternatePostalCode;
                        }
                        logger.debug("preferredPostalCode: ", preferredPostalCode);

                        // ===========================
                        // Vendor Data
                        // ===========================
                        const selectProviderBasedOnUrl = () => {
                            if (vendorCode) {
                                logger.debug("vendor code exists and is:", vendorCodeDecoded);
                                let providers = data.providers;
                                let vendorCodeAsInt = parseInt(vendorCodeDecoded);
                                logger.debug("typeof vendorCodeAsInt", typeof vendorCodeAsInt);
                                logger.debug("vendorCodeAsInt", vendorCodeAsInt);
                                let selectedProvider = providers.find((provider: any) => provider.vendorCode === vendorCodeAsInt);
                                return selectedProvider;
                            }
                        }

                        let provider = selectProviderBasedOnUrl();

                        logger.info("the provider is: ", provider);

                        let vendorData = formatVendorDataObject(provider);
                        setVendorData(vendorData);

                        // ===========================
                        // View Data (for mustache)
                        // ===========================
                        let caseStatus = data.case.status;

                        // logger.info("getting status description...")
                        let programId = data.case.programId;
                        API.getStatusDescription(caseStatus, backendApiUrl, programId)
                            .then(res => {
                                logger.debug("%cstatus res: ", colors2, res);

                                let description = res.data.description;

                                let viewData: ViewDataInterface = {
                                    status: caseStatus,
                                    status_explanation: description,
                                    zip_code: preferredPostalCode,
                                    closest_provider_address: `${provider.streetAddress}, ${provider.city}, ${provider.state} ${provider.postalcode}`,
                                    date_exams_sent: dateExamsSent,
                                    exam_type: provider.examName.toLowerCase(),
                                    provider_name: provider.name,
                                    provider_phone: provider.phoneNumbers[0].value,
                                }
                                logger.info("viewData: ", viewData);

                                setViewData(viewData);
                                setOriginalProviderAddress(viewData.closest_provider_address);


                                getConfidentialityNotice();
                                getClientData();
                                getMessageChoicesByTemplateGroup("applicants");
                                getMessageChoicesByTemplateGroup("vendors");
                                // getMessageChoicesByTemplateGroup("candidates");

                                if (userName) {
                                    logger.info("userNameDecoded: ", userNameDecoded);
                                    API.getUserByUserName(backendApiUrl, userNameDecoded, reqBody)
                                        .then(res => {
                                            logger.info("userName res: ", res);
                                            if (res.data === "") {
                                                setError(true);
                                                setSenderName("");
                                                setSenderData({
                                                    phone: "",
                                                    fax: "",
                                                    email: ""
                                                })
                                                setLoading(false);
                                                setLoaded(true);
                                            }
                                            else {
                                                setSenderName(`${res.data.firstName} ${res.data.lastName}`);
                                                const hasFax = res.data.faxNumbers && res.data.faxNumbers.length > 0;
                                                const hasPhone = res.data.phoneNumbers && res.data.phoneNumbers.length > 0;
                                                const senderEmail = res.data.email;
                                                const senderFax = hasFax ? res.data.faxNumbers[0] : ""
                                                const senderPhone = hasPhone ? res.data.phoneNumbers[0] : ""
                                                setSenderData({
                                                    phone: senderPhone,
                                                    fax: senderFax,
                                                    email: senderEmail
                                                })
                                                setLoading(false);
                                                setLoaded(true);
                                            }
                                        })
                                        .catch(err => {
                                            logger.error(err);
                                            setError(true);
                                            setLoading(false);
                                            setLoaded(true);
                                        })
                                }

                            })
                            .catch(err => {
                                logger.error("%cerror getting status description", colors2, err);
                                setErrorMsg('Error retrieving status description');
                                setError(true);
                                setLoading(false);
                                setLoaded(true);
                            })

                    })
                    .catch(err => {
                        logger.error("here is applicant data err: ", err);
                        setErrorMsg('Error retrieving applicant data');
                        setError(true);
                        setLoading(false);
                        setLoaded(true);
                    })
            }
        }



        setLoading(true);

        // Url params, before they are decoded
        logger.info({ verificationCode, applicantId, caseExamId, vendorCode, userName });


        // take the url param as a base64EncodedString and decode it into plain text
        // the verificationCode stays as a base64 string. The rest (applicantId, caseExamId, vendorCode, and userName are all converted from base64 into plain text strings)
        let applicantIdDecoded = (Buffer.from(applicantId, 'base64')).toString();
        let caseExamIdDecoded = (Buffer.from(caseExamId, 'base64')).toString();
        let vendorCodeDecoded = (Buffer.from(vendorCode, 'base64')).toString();
        let userNameDecoded = (Buffer.from(userName, 'base64')).toString();

        // Url params, after they are decoded
        logger.info({ applicantIdDecoded, caseExamIdDecoded, vendorCodeDecoded, userNameDecoded });

        getLoginToken()
            .then(() => {

                if (verificationCode) {
                    let reqBody: VerifyGuidReqBody = {
                        guid: verificationCode,
                        idValue: caseExamId
                    }

                    logger.info("guid verification req body is: ", reqBody);

                    API.verifyGuid(backendApiUrl, reqBody)
                        .then(res => {
                            logger.info("verify guid result: ", res);
                            getApplicantAndVendorData()
                        })
                        .catch(err => {
                            logger.info("guid is not valid")
                            logger.error(err);
                            setError(true);
                            setErrorMsg('Invalid token');
                            setLoaded(true);
                            setLoading(false);
                        })
                } else {
                    setError(true);
                    setErrorMsg('No verification code');
                    setLoaded(true);
                    setLoading(false);
                }

            })
            .catch(err => {
                logger.error("login error:::", err);
                setError(true);
                setErrorMsg('Login error');
                setLoaded(true);
                setLoading(false);
            })
    }, [verificationCode, applicantId, caseExamId, vendorCode, userName, backendApiUrl, logger]);


    let isNewProviderAddressEmpty: boolean = confirmReassignmentChosen && viewData.closest_provider_address === "";


    // const getTestData = () => {
    //     API.getTestData(backendApiUrl)
    //         .then(res => res.json())
    //         .then(data => console.log("without cache: ", data))
    //         .catch(err => console.log(err))
    // }

    // const getTestDataFromCache = () => {
    //     API.getTestDataFromCache(backendApiUrl)
    //         .then(res => res.json())
    //         .then(data => console.log("from cache: ", data))
    //         .catch(err => console.log(err))
    // }

    // const getCacheKeys = () => {
    //     API.getCacheKeys(backendApiUrl)
    //         .then(res => console.log("keys from cache: ", res))
    //         .catch(err => console.log(err))
    // }


    const showSelectVendorMessagesScreen = selectedEntity === EntityTypeEnum.VENDOR && vendorChosen && !showFinalVendorSendMessageScreen && selectedVendorRecipientEmail && isVendorRecipientSelected;

    return (
        <div>
            <Row>
                <Col sm={12}>

                    {/* <pre>{JSON.stringify(viewData, null, 4)}</pre> */}

                    <div style={{ display: showCacheControls }}>
                        {cacheKeys.map((item: string, index: number) => (
                            <div key={index} className="d-flex justify-content-between mb-2" style={{ maxWidth: "200px" }}>
                                <span>{item}</span>
                                <button onClick={() => removeCacheKey(item)}>remove</button>
                            </div>
                        ))}

                        <input value={newCacheKey} onChange={e => setNewCacheKey(e.target.value)} />
                        <button onClick={addCacheKey}>Add key</button>

                        <div className="mt-2">
                            <button onClick={clearCache} disabled={isClearingCache || cacheKeys.length < 1}> {isClearingCache ? "clearing..." : "clear selected cache"}</button>
                        </div>

                        {cacheCleared && "Succesfully Cleared ✔"}
                        {cacheClearError && <div className="p-2 redBadge">Cache clearing error</div>}

                        {/* <div className="mt-2">
                            <button onClick={getTestData}>getTestData</button>
                            <button onClick={getTestDataFromCache}>getTestDataFromCache</button>
                            <button onClick={getCacheKeys}>getCacheKeys</button>
                        </div> */}
                    </div>


                    {error && <div className="p-3 redBadge">Error loading data{errorMsg && `. ${errorMsg}.`}</div>}
                    {loading && <LoadingSpinnerText spinnerColor="primary" />}
                    {loaded && !error &&
                        <>

                            <SelectedEntitySection
                                selectedEntity={selectedEntity}
                                selectEntity={selectEntity}
                                applicantData={applicantData}
                                vendorData={vendorData}
                            />

                            {selectedEntity === EntityTypeEnum.VENDOR && isVendorRecipientSelected === false &&
                                <ChooseVendorEmailRecipient
                                    selectedVendorRecipientEmail={selectedVendorRecipientEmail}
                                    setSelectedVendorRecipientEmail={setSelectedVendorRecipientEmail}
                                    vendorData={vendorData}
                                />
                            }

                            {selectedEntity === EntityTypeEnum.VENDOR && isVendorRecipientSelected === true &&
                                <div className="mb-3">
                                    <h5>Email address selected: <span className="fw-lighter"> {selectedVendorRecipientEmail}</span></h5>
                                </div>
                            }

                            {/* =========================================================================================== */}
                            {/* If selected entity is Applicant */}
                            {/* =========================================================================================== */}
                            {selectedEntity === EntityTypeEnum.APPLICANT && applicantChosen && !sendingMessages && !showFinalApplicantSendMessageScreen &&
                                <>
                                    <Row>
                                        <Col>
                                            <h6>Choose a message to send:</h6>

                                            <MessagesSearchBar
                                                inputName="searchText"
                                                searchText={searchText}
                                                handleSearchMessages={e => { searchMessages(e.target.value, EntityTypeEnum.APPLICANT); setSearchText(e.target.value); }}
                                            />

                                            <div className="form-text">filter messages (showing {applicantResults.length} of {applicantResults.length}) </div>

                                            <div className="messagesList">
                                                {applicantResults.map(message => (
                                                    <MessageCard
                                                        key={message.id}
                                                        onClick={() => chooseApplicantMessage(message)}
                                                        className={chosenApplicantMessageIds.includes(message.id) ? chosenMessageStyle : notChosenMessageStyle}
                                                        isSelected={chosenApplicantMessageIds.includes(message.id)}
                                                        message={message}
                                                        viewData={viewData}
                                                    />
                                                ))}
                                            </div>

                                        </Col>
                                        <Col>

                                            <NotesField setNotesField={setNotesField} />

                                            {confirmReassignmentChosen && <ReassignmentRequestConfirmedInputField updateClosestProvider={updateClosestProvider} closestProviderAddress={viewData.closest_provider_address} />}

                                            <MessagePreviewSection
                                                messagesToSend={applicantMessagesToSend}
                                                senderName={senderName}
                                                senderData={senderData}
                                                notes={notesField}
                                                viewData={viewData}
                                                recipientName={`${applicantData.firstName} ${applicantData.lastName}`}
                                                recipientEmail={applicantData.email}
                                            />

                                        </Col>
                                    </Row>


                                    <ButtonNavigation
                                        handleCancel={handleCancel}
                                        goBack={applicantGoBack}
                                        goNext={goToFinalApplicantScreen}
                                        isDisabled={applicantMessagesToSend.length < 1 || isNewProviderAddressEmpty}
                                    />

                                </>
                            }

                            {showFinalApplicantSendMessageScreen &&
                                <FinalMessageScreen
                                    messagesToSend={applicantMessagesToSend}
                                    senderName={senderName}
                                    senderData={senderData}
                                    notes={notesField}
                                    viewData={viewData}
                                    recipientName={`${applicantData.firstName} ${applicantData.lastName}`}
                                    recipientEmail={applicantData.email}
                                    messagesSent={messagesSent}
                                    sendingMessages={sendingMessages}
                                    handleCancel={handleCancel}
                                    sendMessages={sendMessages}
                                    goBack={() => setShowFinalApplicantSendMessageScreen(false)}
                                />
                            }


                            {/* =========================================================================================== */}
                            {/* If selected entity is Vendor */}
                            {/* =========================================================================================== */}

                            {selectedEntity === EntityTypeEnum.VENDOR && isVendorRecipientSelected === false &&
                                <ButtonNavigation
                                    handleCancel={handleCancel}
                                    goBack={() => {
                                        setVendorChosen(false);
                                        setSelectedEntity("");
                                        setVendorSearchText("");
                                        setVendorResults(vendorMessageChoices);
                                        setVendorMessagesToSend([]);
                                        setChosenVendorMessageIds([]);

                                        setIsVendorRecipientSelected(false);
                                        setSelectedVendorRecipientEmail("");
                                    }}
                                    goNext={() => setIsVendorRecipientSelected(true)}
                                    isDisabled={!selectedVendorRecipientEmail}
                                />
                            }

                            {showSelectVendorMessagesScreen &&
                                <>
                                    <Row>
                                        <Col>
                                            <h6>Choose a message to send:</h6>


                                            <MessagesSearchBar
                                                inputName="vendorSearch"
                                                searchText={vendorSearchText}
                                                handleSearchMessages={e => { searchMessages(e.target.value, EntityTypeEnum.VENDOR); setVendorSearchText(e.target.value); }}
                                            />

                                            <div className="form-text">filter messages (showing {vendorResults.length} of {vendorResults.length}) </div>

                                            {vendorResults.map(message => (
                                                <MessageCard
                                                    key={message.id}
                                                    onClick={() => chooseVendorMessage(message)}
                                                    className={chosenVendorMessageIds.includes(message.id) ? chosenMessageStyle : notChosenMessageStyle}
                                                    isSelected={chosenVendorMessageIds.includes(message.id)}
                                                    message={message}
                                                    viewData={viewData}
                                                />
                                            ))}


                                        </Col>
                                        <Col>
                                            <NotesField setNotesField={setNotesField} />

                                            <MessagePreviewSection
                                                messagesToSend={vendorMessagesToSend}
                                                senderName={senderName}
                                                senderData={senderData}
                                                notes={notesField}
                                                viewData={viewData}
                                                recipientName={vendorData.vendorName}
                                                recipientEmail={selectedVendorRecipientEmail}
                                            />

                                        </Col>
                                    </Row>

                                    <ButtonNavigation
                                        handleCancel={handleCancel}
                                        goBack={vendorGoBackFromMessageChoices}
                                        goNext={goToFinalVendorScreen}
                                        isDisabled={vendorMessagesToSend.length < 1}
                                    />

                                </>
                            }

                            {showFinalVendorSendMessageScreen &&
                                <FinalMessageScreen
                                    messagesToSend={vendorMessagesToSend}
                                    senderName={senderName}
                                    senderData={senderData}
                                    notes={notesField}
                                    viewData={viewData}
                                    recipientName={vendorData.vendorName}
                                    recipientEmail={selectedVendorRecipientEmail}
                                    messagesSent={messagesSent}
                                    sendingMessages={sendingMessages}
                                    handleCancel={handleCancel}
                                    sendMessages={sendMessages}

                                    goBack={() => setShowFinalVendorSendMessageScreen(false)}
                                />
                            }


                            {/* =========================================================================================== */}
                            {/* If selected entity is DoDMERB */}
                            {/* =========================
                            ================================================================== */}
                            {selectedEntity === EntityTypeEnum.DODMERB && !messagesSent && !dodmerbPersonnelChosen &&
                                <>
                                    <ChoosePersonnel
                                        selectPersonnel={selectPersonnel}
                                        clientData={clientData}
                                        handleCancel={handleCancel}
                                        cancelSelectPersonnel={cancelSelectPersonnel}
                                        setDodmerbPersonnelChosen={setDodmerbPersonnelChosen}
                                        selectedDodmerbPersonnelId={selectedDodmerbPersonnelId}
                                    />
                                </>
                            }

                            {selectedEntity === EntityTypeEnum.DODMERB && dodmerbPersonnelChosen && !messagesSent && selectedDodmerbPersonnelId
                                && !showFinalDodmerbSendMessageScreen &&
                                <>
                                    <Row>
                                        <Col>
                                            <h3>Selected Personnel: {selectedDodmerbPersonnelName}</h3>

                                            <MessagesSearchBar
                                                inputName="dodmerbSearchText"
                                                searchText={dodmerbSearchText}
                                                handleSearchMessages={e => { searchMessages(e.target.value, EntityTypeEnum.DODMERB); setDodmerbSearchText(e.target.value); }}
                                            />

                                            <div className="form-text">filter messages (showing {dodmerbResults.length} of {dodmerbResults.length}) </div>

                                            <h6>Choose a message to send:</h6>
                                            {dodmerbResults.map(message => (
                                                <MessageCard
                                                    key={message.id}
                                                    onClick={() => chooseDodmerbMessage(message)}
                                                    className={chosenDodmerbMessageIds.includes(message.id) ? chosenMessageStyle : notChosenMessageStyle}
                                                    isSelected={chosenDodmerbMessageIds.includes(message.id)}
                                                    message={message}
                                                    viewData={viewData}
                                                />
                                            ))}
                                        </Col>

                                        <Col>
                                            <NotesField setNotesField={setNotesField} />

                                            <MessagePreviewSection
                                                messagesToSend={dodmerbMessagesToSend}
                                                senderName={senderName}
                                                senderData={senderData}
                                                notes={notesField}
                                                viewData={viewData}
                                                recipientName={selectedDodmerbPersonnelName}
                                                recipientEmail={selectedDodmerbPersonnelEmail}
                                            />

                                        </Col>
                                    </Row>

                                    <ButtonNavigation
                                        handleCancel={handleCancel}
                                        goBack={dodmerbGoBack}
                                        goNext={goToFinalDodmerbScreen}
                                        isDisabled={dodmerbMessagesToSend.length < 1}
                                    />
                                </>
                            }

                            {showFinalDodmerbSendMessageScreen &&
                                <FinalMessageScreen
                                    messagesToSend={dodmerbMessagesToSend}
                                    senderName={senderName}
                                    senderData={senderData}
                                    notes={notesField}
                                    viewData={viewData}
                                    recipientName={selectedDodmerbPersonnelName}
                                    recipientEmail={selectedDodmerbPersonnelEmail}
                                    messagesSent={messagesSent}
                                    sendingMessages={sendingMessages}
                                    handleCancel={handleCancel}
                                    sendMessages={sendMessages}
                                    goBack={() => setShowFinalDodmerbSendMessageScreen(false)}
                                />
                            }
                        </>
                    }

                </Col>
            </Row>

        </div >
    )
}