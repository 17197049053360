import { Spinner } from "react-bootstrap";

interface Props {
    loadingText?: string;
    spinnerColor: string;
}

export default function LoadingSpinnerText({ loadingText, spinnerColor }: Props) {

    return (
        <>
            <span><Spinner data-testid="loadingSpinnerText" animation="border" variant={spinnerColor} size="sm" /> <span data-testid="spinnerText">{loadingText ? loadingText : "Loading"}</span>...</span>
        </>
    )
}
