import RecipientPresenter from '../../utils/Presenters/RecipientPresenter';
import { EntityTypeEnum } from '../ErrorManagement/ErrorManagement';
import RecipientCard from '../RecipientCard/RecipientCard';
import RecipientInfo from '../RecipientInfo/RecipientInfo';

interface Props {
    selectedEntity: boolean | string;
    selectEntity: (entity: EntityTypeEnum) => void;
    applicantData: any;
    vendorData: any;

}

export default function SelectedEntitySection({
    selectedEntity,
    selectEntity,
    applicantData,
    vendorData
}: Props) {

    const vendorHasNoEmailAddress = vendorData && vendorData.email1 === '';


    const recipientPresenter = new RecipientPresenter();


    const getRecipientName = () => {
        let name: string = "";
        if (typeof selectedEntity === 'string') {
            name = recipientPresenter.formatName(selectedEntity)
        }
        return name;
    }

    let recipient = getRecipientName();

    return (
        <div>
            {!selectedEntity ?
                <>
                    <h3>Please select a recipient</h3>

                    <RecipientCard onClick={() => selectEntity(EntityTypeEnum.APPLICANT)}>
                        {applicantData &&
                            <RecipientInfo entityType={EntityTypeEnum.APPLICANT} data={applicantData} />
                        }
                    </RecipientCard>

                    <RecipientCard onClick={() => vendorHasNoEmailAddress ? null : selectEntity(EntityTypeEnum.VENDOR)} isDisabled={vendorHasNoEmailAddress}>
                        {vendorData &&
                            <RecipientInfo entityType={EntityTypeEnum.VENDOR} data={vendorData} />
                        }
                    </RecipientCard>

                    <RecipientCard onClick={() => selectEntity(EntityTypeEnum.AIR_FORCE)}>
                        <h5 className="mb-0">Air Force</h5>
                    </RecipientCard>
                </>
                :
                <>
                    <h3 className="">Selected recipient: {recipient}</h3>

                    {selectedEntity === EntityTypeEnum.APPLICANT &&
                        <div className="card mb-2 lightCardNoHover p-3 shadow-sm" onClick={() => selectEntity(EntityTypeEnum.APPLICANT)}>
                            {applicantData &&
                                <RecipientInfo entityType={EntityTypeEnum.APPLICANT} data={applicantData} />
                            }
                        </div>
                    }

                    {selectedEntity === EntityTypeEnum.VENDOR &&
                        <div className="card mb-2 lightCardNoHover p-3 shadow-sm" onClick={() => selectEntity(EntityTypeEnum.VENDOR)}>
                            {vendorData &&
                                <RecipientInfo entityType={EntityTypeEnum.VENDOR} data={vendorData} />
                            }
                        </div>
                    }
                </>
            }

        </div>
    )
}