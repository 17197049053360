import { Col, Row } from 'react-bootstrap'
import { exclamationCircleFillIconSrc } from '../../styles/icons/icons';
import { EntityTypeEnum } from '../ErrorManagement/ErrorManagement'

interface Props {
    entityType: EntityTypeEnum;
    data: any;
}

export default function RecipientInfo({ entityType, data }: Props) {

    const vendorHasNoEmailAddress = data && data.email1 === "";
    return (
        <div>


            {entityType === EntityTypeEnum.APPLICANT &&
                <Row>
                    <h5 className="mb-0">Applicant</h5>
                    <Col lg={3}>
                        <h6 className="mb-0">First Name: <span className="fw-lighter" data-testid="firstName">{data.firstName}</span></h6>
                        <h6 className="mb-0">Last Name: <span className="fw-lighter" data-testid="lastName">{data.lastName}</span></h6>
                        <h6 className="mb-0">Birth Sex: <span className="fw-lighter" data-testid="sexAtBirth">{data.sexAtBirth}</span></h6>
                    </Col>
                    <Col>
                        <h6 className="mb-0">Last 4 SSN: <span className="fw-lighter" data-testid="ssnLast4">{data.ssnLast4}</span></h6>
                        <h6 className="mb-0">DoB: <span className="fw-lighter" data-testid="dob">{data.dob}</span></h6>
                    </Col>
                </Row>
            }


            {entityType === EntityTypeEnum.VENDOR &&
                <Row>
                    <h5 className="mb-0">Vendor</h5>
                    {vendorHasNoEmailAddress &&
                        <div className="text-danger">
                            <img src={exclamationCircleFillIconSrc} alt="exclamation icon" className="filter-red" /> Vendor has no email address. Fax coming soon.
                        </div>
                    }

                    <Col lg={3}>
                        <h6 className="mb-0">Vendor Code: <span className="fw-lighter" data-testid="vendorCode">{data.vendorCode}</span></h6>
                        <h6 className="mb-0">Name: <span className="fw-lighter" data-testid="vendorName">{data.vendorName}</span></h6>
                        <h6 className="mb-0">Vendor Type: <span className="fw-lighter" data-testid="vendorType">{data.vendorType}</span></h6>
                    </Col>
                    <Col>
                        <h6 className="mb-0">Address: <span className="fw-lighter" data-testid="address">{data.address} {data.city}, {data.state} {data.postalcode}</span></h6>
                    </Col>
                </Row>
            }

        </div>
    )
}