import { Message } from "./interfaces";

export function formatDate(date: Date) {
    let formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;

    return formattedDate;
}

export function formatVendorDataObject(provider: any) {

    const doesVendorHaveEmailAddress = provider.emails && provider.emails.length > 0; // if vendor has no emails, set email1 to "" and set emails to [];

    let vendorData = {
        vendorName: provider.name,
        address: provider.streetAddress,
        city: provider.city,
        state: provider.state,
        postalcode: provider.postalcode,
        vendorType: provider.examName,
        vendorCode: provider.vendorCode,
        email1: doesVendorHaveEmailAddress ? provider.emails[0].value : "",
        emails: doesVendorHaveEmailAddress ? provider.emails : []
    }

    return vendorData;
}

export function formatApplicantDataObject(applicant: any) {

    let birthDay = new Date(applicant.dateOfBirth);
    let formattedBirthDay = formatDate(birthDay);

    let applicantData = {
        firstName: applicant.firstName.toUpperCase(),
        lastName: applicant.lastName.toUpperCase(),
        ssnLast4: applicant.ssnLast4,
        dob: formattedBirthDay,
        email: applicant.email,
        sexAtBirth: applicant.sexAtBirth
    }

    return applicantData;
}

export const formatMessageBody = (messages: string[], notesField: string, senderName: string) => {

    const greeting = `Hello ${senderName}, <br/>`;

    if (notesField !== "") {
        let messageBody = greeting.concat(messages.join("<br/>").concat(`<br/> Notes: ${notesField}`));
        return messageBody;
    } else {
        let messageBody = greeting.concat(messages.join("<br/>"));
        return messageBody;
    }
}

export const formatMessageSubject = (messages: Message[]) => {
    if (messages.length > 1) {
        let subject = `DoDMETS -${messages[0].subject} and ${messages.length - 1} more...`;
        return subject;
    } else {
        let subject = `DoDMETS -${messages[0].subject}`;
        return subject
    }
}

export const addDays = (days: number) => {
    const today = new Date();
    // Add n days
    let futureDateAsNumber = today.setDate(today.getDate() + days);
    // format as Date object
    let futureDateAsDate = new Date(futureDateAsNumber);
    return futureDateAsDate;
}

export const determineLaterDate = (dates: Date[]) => {

    // map over each date and getTime
    let arr = dates.map(date => date.getTime());

    let sorted = arr.sort(function (a, b) { return b - a });
    let firstItem = sorted[0];
    // return 0th element as Date
    let latestDate = new Date(firstItem);

    return latestDate
}

export const removeAllHtmlTagsFromMessage = (str: string) => {
    let withoutTags = str.replace(/(<([^>]+)>)/ig, '');
    return withoutTags;
}