
interface Props {
    setNotesField: (event: string) => void;
}

export default function NotesField({ setNotesField }: Props) {
    return (
        <>
            <div className="mt-3">Enter any extra notes you'd like to include:</div>
            <textarea data-testid="notesFieldTextarea" className="form-control" onChange={e => setNotesField(e.target.value)} />
        </>
    )
}