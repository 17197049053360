import { EntityTypeEnum } from "../../components/ErrorManagement/ErrorManagement";

class RecipientPresenter {
    formatName(recipient: string) {
        let name: string = "";
        if (recipient === EntityTypeEnum.AIR_FORCE) {
            name = 'Air Force'
        } else if (recipient === EntityTypeEnum.APPLICANT) {
            name = 'Applicant'
        } else if (recipient === EntityTypeEnum.VENDOR) {
            name = 'Vendor'   
        }

        return name;
    }
}


export default RecipientPresenter;