import { partials } from "../components/ErrorManagement/ErrorManagement";
import {ViewDataInterface} from "./interfaces";
import Mustache from "mustache";

export function createMarkup(mustacheTemplate: string) {
    let markup = { __html: mustacheTemplate };
    return (<div dangerouslySetInnerHTML={markup} />)
}

export const renderMustacheTemplate = (template: string, viewData: ViewDataInterface) => {

    let view = {
        body: template,
        ...viewData
    };

    let mustacheOutput = Mustache.render(template, view, partials);

    let htmlOutput = createMarkup(mustacheOutput);
    return htmlOutput;
}

