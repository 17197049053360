const dodmerbMessageChoices = [
    {
        id: "1",
        text: "Applicant has withdrawn from program",
        subject: "Applicant has withdrawn"
    }, {
        id: "2",
        text: "Applicant is unresponsive and will not reply to messages",
        subject: "No response from applicant",
    }
];

export default dodmerbMessageChoices;