
interface Props {
    handleCancel: () => void;
    goBack: () => void;
    goNext: () => void;
    isDisabled: boolean;
}

export default function ButtonNavigation({handleCancel, goBack, goNext, isDisabled }: Props) {
    return (
        <div className="mt-3 d-flex justify-content-between">
            <button onClick={handleCancel} data-testid="cancelBtn" className="btn btn-outline-primary">Cancel</button>
            <div>
                <button onClick={goBack} data-testid="backBtn" className="btn btn-primary">Back</button>{' '}
                <button onClick={goNext} data-testid="nextBtn" className="btn btn-primary" disabled={isDisabled}>Next</button>
            </div>
        </div>
    )
}