
interface Props {
    onClick: () => void;
    children: JSX.Element | JSX.Element[];
    isDisabled?: Boolean;
}

export default function RecipientCard({ onClick, children, isDisabled }: Props) {
    const disabledStyle = "card mb-2 p-3 shadow-sm greyBackground text-muted";
    return (
        <div className={isDisabled ? disabledStyle : "card mb-2 lightCard pointer p-3 shadow-sm"} onClick={onClick} data-testid="recipientCard">
            {children}
        </div>
    )
}