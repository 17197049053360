import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { checkCircleFillIconSrc } from "../../styles/icons/icons";
import { removeAllHtmlTagsFromMessage } from "../../utils/helperFunctions";
import { Message, ViewDataInterface } from '../../utils/interfaces';
import { renderMustacheTemplate } from '../../utils/mustacheFunctions';

interface Props {
    onClick: () => void;
    className: string;
    message: Message;
    isSelected: boolean;
    viewData: ViewDataInterface;
}

export default function MessageCard({ onClick, className, message, isSelected, viewData }: Props) {

    const maxMessageLength: number = 90;

    let msgWithoutTags = removeAllHtmlTagsFromMessage(message.text);

    const renderTooltip = (props: any) => {
        return (
            <Tooltip id="button-tooltip" {...props}>
                {renderMustacheTemplate(message.text, viewData)}
            </Tooltip>
        )
    }


    return (
        <div onClick={onClick} className={className}>
            <div data-testid="iconDiv">
                    <img
                        src={checkCircleFillIconSrc} 
                        alt="check circle fill icon"
                        data-testid="icon"
                        className={isSelected ? 'filter-blue' : 'filter-very-light-grey'}
                    /> <span className="fw-bold" data-testid="subject">
                    {message.subject}
                </span>
            </div>
            <p className="mb-0" data-testid="messageText">
                {message.text.length > maxMessageLength ?
                    <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 400 }}
                        overlay={renderTooltip}
                    >
                        <span data-testid="msgWithouTagsAbbreviated">
                            {msgWithoutTags.slice(0, maxMessageLength)}...
                        </span>
                    </OverlayTrigger>
                    :
                    msgWithoutTags
                }
            </p>
        </div>
    )
}