import axios from 'axios';
import { MessageLog, MsgData, VerifyGuidReqBody } from './interfaces';

interface GetApplicantDataReqBody {
    ApplicantId: number;
}


const API = {
    /**
     * Send a quick message
     * @param msgData 
     * @param backendApiUrl 
     * @returns 
     */
    sendCannedMessage: function (msgData: MsgData, backendApiUrl: string) {
        return axios.post(`${backendApiUrl}/api/messages`, msgData);
    },
    
    getMessageChoicesByTemplateGroup: function (templateGroup: string, backendApiUrl: string) {
        return axios.get(`${backendApiUrl}/api/messages/templates`, { params: { templateGroup } });
    },
    
    getClientData: function (backendApiUrl: string, reqBody: VerifyGuidReqBody) {
        return axios.post(`${backendApiUrl}/api/client-data`, reqBody)
    },
    
    loginToBackend: function (backendApiUrl: string) {
        return axios.get(`${backendApiUrl}/api/auth/login`)
    },
    
    getApplicantData: function (reqBody: GetApplicantDataReqBody, backendApiUrl: string) {
        return axios.post(`${backendApiUrl}/api/basic/applicant`, reqBody);
    },

    getConfidentialityNotice: function (backendApiUrl: string) {
        return axios.get(`${backendApiUrl}/api/messages/confidentiality-notice`)
    },
    
    getUserByUserName: function (backendApiUrl: string, userName: string, reqBody: VerifyGuidReqBody) {
        return axios.post(`${backendApiUrl}/api/user`, reqBody, { params: { userName } })
    },

    createMessageLog: function (backendApiUrl: string, msgLog: MessageLog) {
        return axios.post(`${backendApiUrl}/api/message-logs`, msgLog)
    },
    
    getStatusDescription: function (caseStatus: string, backendApiUrl: string, programId: number) {
        return axios.get(`${backendApiUrl}/api/cases/status`, { params: { caseStatus, programId } })
    },

    // clear some or all of the keys in the cache
    clearCache: function (backendApiUrl: string, cacheKeys: string[]) {
        return axios.post(`${backendApiUrl}/api/cache/clear`, cacheKeys)
    },

    verifyGuid: function (backendApiUrl: string, reqBody: VerifyGuidReqBody) {
        return axios.post(`${backendApiUrl}/api/auth/guid`, reqBody)
    },

    // ===============================
    // Azure functions
    // ===============================

    getEnvironmentSettings: function () {
        return axios.get(`/api/environment`)
    }
}


export default API;