import { checkCircleFillIconSrc } from "../../styles/icons/icons";

interface Props {
    handleCancel: () => void;
}

export default function MessagesSentSuccessfullySection({ handleCancel }: Props) {
    return (
        <div className="p-3 greenBadge" data-testid="messagesSuccessfullySentSectionDiv">
            <img src={checkCircleFillIconSrc} alt="check circle icon" className="filter-light-green" /> <span>Message Sent</span>
            <div className="mt-2">
                <button data-testid="messageButton" onClick={handleCancel} className="btn btn-primary btn-sm">Send another message</button>
            </div>
        </div>
    )
}