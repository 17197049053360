import { SenderDataInterface } from '../../utils/interfaces';
import LoadingSpinnerText from '../LoadingSpinnerText/LoadingSpinnerText'
import MessagePreviewSection from '../MessagePreviewSection/MessagePreviewSection'
import MessagesSentSuccessfullySection from '../MessagesSentSuccessfullySection/MessagesSentSuccessfullySection'

interface Props {
    recipientName: string;
    messagesToSend: any;
    notes: any;
    viewData: any;
    recipientEmail: any;
    senderName: string;
    senderData: SenderDataInterface;


    messagesSent: boolean;
    handleCancel: () => void;
    sendingMessages: boolean;
    sendMessages: () => void;
    goBack: () => void;
}

export default function FinalMessageScreen({
    recipientName,
    messagesToSend,
    notes,
    viewData,
    recipientEmail,
    senderName,
    senderData,

    messagesSent,
    handleCancel,
    sendingMessages,
    sendMessages,

    goBack

}: Props) {
    return (
        <div>
            <div>
                <h4 data-testid="finalMessagesScreenHeader">Here's what you will send</h4>

                <div className="mb-3">
                    <p className="mb-1">Send email to:</p>
                    <h6>Name: <span className="fw-lighter" data-testid="recipientName">{recipientName}</span></h6>
                </div>

                <MessagePreviewSection
                    messagesToSend={messagesToSend}
                    senderName={senderName}
                    senderData={senderData}
                    notes={notes}
                    viewData={viewData}
                    recipientName={recipientName}
                    recipientEmail={recipientEmail}
                />
            </div>


            {!messagesSent &&
                <div className="mt-3 d-flex justify-content-between" data-testid="finalSendMessageDiv">
                    <button onClick={handleCancel} data-testid="cancelSendMsgBtn" className="btn btn-outline-primary" disabled={sendingMessages}>Cancel</button>

                    <div>
                        <button onClick={goBack} data-testid="backBtn" className="btn btn-primary me-1">Back</button>
                        <button onClick={sendMessages} className="btn btn-primary" disabled={sendingMessages}>
                            {sendingMessages ? <LoadingSpinnerText loadingText="Sending" spinnerColor="light" />
                                :
                                <><i className="far fa-paper-plane" /> Send message</>
                            }
                        </button>
                    </div>
                </div>
            }

            {messagesSent &&
                <MessagesSentSuccessfullySection handleCancel={handleCancel} />
            }
        </div>
    )
}