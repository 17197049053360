import { Message, SenderDataInterface, ViewDataInterface } from '../../utils/interfaces';
import { Card } from 'react-bootstrap';
import { renderMustacheTemplate } from '../../utils/mustacheFunctions';

interface Props {
    recipientEmail: string;
    recipientName: string;
    subject: string;
    messages: Message[];
    notes?: string;
    viewData: ViewDataInterface;
    senderName: string;
    senderData: SenderDataInterface;
}

export default function ErrorManagementMessagePreview({ recipientEmail, recipientName, subject, messages, notes, viewData, senderName, senderData }: Props) {
    let senderPhone = senderData.phone;
    let senderEmail = senderData.email;
    let senderFax = senderData.fax;

    return (
        <>
            <Card className="shadow-sm p-3 mb-3 bg-body rounded">
                <h6>To: <a href={`mailto: ${recipientEmail}`}>{recipientEmail}</a></h6>
                <h6>Subject:</h6>
                <Card className="p-2 mb-2">
                    {subject ? subject : <span style={{ visibility: "hidden" }}>a</span>}
                </Card>
                <h6>Body:</h6>
                <Card className="p-2">
                    <div>Hello {recipientName},
                        <br />
                        <br />

                        {messages.length === 0 ? <div className="text-muted greyBackground p-3 rounded">(Select a message)</div>
                            :
                            <>
                                {messages.map((message, index) => (
                                    <div key={index}>
                                        <p className="fw-bold">{index + 1}. {message.subject} </p>
                                        <div>{renderMustacheTemplate(message.text, viewData)}</div>
                                    </div>
                                ))}
                            </>
                        }

                        <br />

                        {notes &&
                            <div className="mt-2 mb-3">
                                Notes: {notes}
                            </div>
                        }

                        <div>
                            <p>Thank you,</p>
                            <p className="mb-0">{senderName}</p>
                            {senderPhone && <p className="mb-0">T: {senderPhone}</p>}
                            {senderFax && <p className="mb-0">F: {senderFax}</p>}
                            {senderEmail && <p className="mb-0"><a href={`mailto:${senderEmail}`}>{senderEmail}</a></p>}
                            <p className="mb-0"><a href="https://civteam.com" target="_blank" rel="noreferrer">www.civteam.com</a></p>
                            <p>701 Market St Ste 3500, Philadelphia PA 19106</p>
                        </div>
                    </div>


                </Card>
            </Card>
        </>
    )
}