
interface Props {
    selectPersonnel: (person: string) => void;
    clientData: any;

    handleCancel: () => void;
    cancelSelectPersonnel: () => void;
    setDodmerbPersonnelChosen: (isChosen: boolean) => void;
    selectedDodmerbPersonnelId: string;
}

export default function ChoosePersonnel({
    selectPersonnel, clientData, handleCancel, cancelSelectPersonnel, setDodmerbPersonnelChosen, selectedDodmerbPersonnelId
}: Props) {
    return (
        <div>
            <p>Send email to:</p>
            <select className="form-select form-select-lg" onChange={e => selectPersonnel(e.target.value)} defaultValue={""}>
                <option value="" disabled >Select personnel</option>
                {clientData.map((person: any, index: number) => (
                    <option key={person.email + index.toString()} value={person.id}> {person.lastName}, {person.firstName} {`(${person.email})`}</option>))}
            </select>

            <div className="mt-3 d-flex justify-content-between">
                <button onClick={handleCancel} className="btn btn-outline-primary">Cancel</button>
                <div>
                    <button onClick={cancelSelectPersonnel} className="btn btn-primary">Back</button>{' '}
                    <button onClick={() => setDodmerbPersonnelChosen(true)} className="btn btn-primary" disabled={!selectedDodmerbPersonnelId}>Next</button>
                </div>
            </div>
        </div>
    )
}