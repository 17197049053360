
interface Props {
    updateClosestProvider: (closestProvider: string) => void;
    closestProviderAddress: string;
}

export default function ReassignmentRequestConfirmedInputField({ updateClosestProvider, closestProviderAddress }: Props) {
    return (
        <div>
            <div className="text-danger">Modify provider address here (if needed):</div>
            <textarea data-testid="closestProviderTextarea" className="form-control" value={closestProviderAddress} onChange={e => updateClosestProvider(e.target.value)} ></textarea>
        </div>
    )
}