import { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import ErrorManagement from './components/ErrorManagement/ErrorManagement';
import NoMatch from './pages/NoMatch';
import API from './utils/API';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';

const pino = require('pino');


export interface EnvironmentSettings {
  backendApiUrl: string;
  logLevel: string;
  showCacheControls: string;
}



function App() {

  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [logger, setLogger] = useState<any>();


  const [environmentSettings, setEnvironmentSettings] = useState<EnvironmentSettings>({
    backendApiUrl: "",
    logLevel: "",
    showCacheControls: ""
  });



  useEffect(() => {

    API.getEnvironmentSettings()
      .then(res => {

        let pinoLogger = pino({
          level: res.data.logLevel || "silent"
        })

        setLogger(pinoLogger);

        if (res.data.logLevel !== "silent" && res.data.logLevel !== "") {
          console.log("environment settings are: ", res.data)
        }

        setEnvironmentSettings(res.data);
        setLoaded(true);
        setError(false);
      })
      .catch(err => {
        console.log(err);
        setLoaded(false);
        setError(true);
      })

  }, []);






  return (
    <div className="App">

      <Row className="m-3">
        <Col>


          {error ?
            <div className="p-3 redBadge">
              Error loading page
            </div>
            :

            <BrowserRouter>
              <Routes>
                {/* these parameters are base 64 encoded: verificationCode, applicantId, caseExamId, vendorCode, userName */}
                <Route path="/:verificationCode/:applicantId/:caseExamId/:vendorCode/:userName"
                  element={
                    loaded &&
                    <ErrorManagement environmentSettings={environmentSettings} logger={logger} />
                  }
                />
                {/* If any other url is requested send no match page */}
                <Route path="*" element={<NoMatch />} />
              </Routes>
            </BrowserRouter>

          }

        </Col>
      </Row>

    </div>
  );
}

export default App;