import { Message, SenderDataInterface, ViewDataInterface } from '../../utils/interfaces';
import ErrorManagementMessagePreview from '../ErrorManagementMessagePreview/ErrorManagementMessagePreview';

interface Props {
    messagesToSend: Message[];
    notes: string;
    viewData: ViewDataInterface;
    senderName: string;
    senderData: SenderDataInterface;
    recipientName: string;
    recipientEmail: string;
}

export default function MessagePreviewSection({ messagesToSend, notes, viewData, senderName, senderData, recipientName, recipientEmail }: Props) {


    const formatSubject = (messagesLength: number) => {
        let subj: string = "";
        messagesLength > 1 ? subj = `DoDMETS -${messagesToSend[0].subject} and ${messagesToSend.length - 1} more...` :
            messagesLength === 1 ? subj = `DoDMETS -${messagesToSend[0].subject}` :
                subj = "";
        return subj;
    }

    let subject = formatSubject(messagesToSend.length);

    return (
        <div>

            <h4 data-testid="header">Preview your message(s)</h4>

            <ErrorManagementMessagePreview
                recipientEmail={recipientEmail}
                recipientName={recipientName}
                subject={subject}
                messages={messagesToSend}
                notes={notes}
                viewData={viewData}
                senderName={senderName}
                senderData={senderData}
            />

        </div>
    )
}