
interface Props {
    selectedVendorRecipientEmail: string;
    vendorData: any;
    setSelectedVendorRecipientEmail: (recipient: string) => void;
}

export default function ChooseVendorEmailRecipient({selectedVendorRecipientEmail, vendorData, setSelectedVendorRecipientEmail }: Props) {
    return (
        <div className="bg-light text-dark p-2">
            <label>Send email to:</label>
            <select className="form-select form-select-lg" onChange={(e) => setSelectedVendorRecipientEmail(e.target.value)} defaultValue={""}>
                <option value="" disabled>Select</option>
                {vendorData.emails && vendorData.emails.length > 0 &&
                    vendorData.emails.map((email: any) => (
                        <option key={email.value} value={email.value}>{email.value}</option>
                    ))
                }
            </select>
        </div>
    )
}